import { gql, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import { Button, ButtonGroup, DateField, LoadingOverlayProvider, LoadingOverlayTrigger, WithLabel } from '@atrocit/scl';
import WorkRecordDockWorker from './lib/WorkRecordsTable';
import { groupByDockWorker } from './lib/utils';
import GenerateHaloCsvModal from './csvgenerator/GenerateHaloCsvModal';
import MarkDownloadedWorkRecordsAsProcessedModal from './MarkDownloadedWorkRecordsAsProcessedModal';
import SelectField from '../lib/selectfields/SelectField';
import RoleSelectField from '../lib/selectfields/RoleSelectField';

export default function WorkRecordsToEnter() {
	const [ showGenerateHaloModal, setShowGenerateHaloModal ] = useState(false);
	const [ showMarkDownloadedWorkRecordsAsProcessedModal, setShowMarkDownloadedWorkRecordsAsProcessedModal ] = useState(null);
	const [ lowerbound, setLowerbound ] = useState(DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 2 }));
	const [ upperbound, setUpperbound ] = useState(DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 2 }));
	const [ roleFilter, setRoleFilter ] = useState(null);
	const [ absenceType, setAbsenceType ] = useState(null);

	const workRecordsQuery = useQuery(gql`query Query($lowerbound: Instant!, $upperbound: Instant!) {
		workRecordsToEnterWithinRange(lowerbound: $lowerbound, upperbound: $upperbound) {
			id,
			needsChange,
			planSpot {
				id,
				planning {
					id,
					shiftDefinition {
						id,
						code,
                    }
				},
                start,
                end,
			},
			dockWorkerTarget {
				id,
				workbookNr,
				category,
				user {
					id,
					fullName,
				}
			},
			startDateTarget,
			endDateTarget,
			absenceTypeTarget,
			roleTarget {
				id, colorCode, shortCode,
			},
			remarkTarget,
			calculatedWageTarget,
			calculatedPremiumTarget,
            warningOnWageCalculationTarget,
		}
	}`, { variables: { lowerbound: lowerbound.toUTC().toISO(), upperbound: upperbound.toUTC().toISO() } });
	const workRecordsRaw = workRecordsQuery?.data?.workRecordsToEnterWithinRange ?? [];

	// a loonlijn either has a roleTarget (if it's a planSpot line) or an absenceTypeTarget (if it's an absence line), never both
	const workRecords = workRecordsRaw.filter(wr => (roleFilter != null ? wr.roleTarget?.id == roleFilter : absenceType != null ? wr.absenceTypeTarget == absenceType : true));

	// Work records per dock worker
	const wrpdw = useMemo(() => groupByDockWorker(workRecords), [ workRecords ]);

	return <>
		<TopBar title="Nieuwe loonlijnen" />
		{showGenerateHaloModal && <GenerateHaloCsvModal workRecords={workRecords} onClose={(wrIds) => { setShowMarkDownloadedWorkRecordsAsProcessedModal(wrIds != null && wrIds.length > 0 ? wrIds : null); setShowGenerateHaloModal(false); }} />}
		{showMarkDownloadedWorkRecordsAsProcessedModal && <MarkDownloadedWorkRecordsAsProcessedModal workRecords={showMarkDownloadedWorkRecordsAsProcessedModal} onClose={() => setShowMarkDownloadedWorkRecordsAsProcessedModal(null)} />}
		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/administration', label: 'CEPA' }, { link: '/administration/workrecords', label: 'Loonlijnen' }, { link: '/administration/workrecords/toEnter', label: 'In te voeren' } ]}>
					 <Button onClick={() => setShowGenerateHaloModal(true)}><span className="fa fa-file-excel-o" />&nbsp; Exporteer HALO CSV</Button>
				</PageTop>

				<div className="col-2" style={{ alignItems: 'flex-end' }}>
					<WithLabel label="Van">
						<DateField onChange={d => setLowerbound(d.startOf('day'))} value={lowerbound} />
					</WithLabel>
					<WithLabel label="T/m">
						<DateField onChange={d => setUpperbound(d.endOf('day'))} value={upperbound} />
					</WithLabel>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<ButtonGroup>
						<Button
							onClick={() => {
								setLowerbound(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 }));
								setUpperbound(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 }));
							}}
							active={lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 }))}
							level={lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
							Twee weken geleden
						</Button>
						<Button
							onClick={() => {
								setLowerbound(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 }));
								setUpperbound(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 }));
							}}
							active={lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 }))}
							level={lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
							Afgelopen week
						</Button>
						<Button
							onClick={() => {
								setLowerbound(DateTime.now().startOf('week').minus({ days: 2 }));
								setUpperbound(DateTime.now().endOf('week').minus({ days: 2 }));
							}}
							active={lowerbound.equals(DateTime.now().startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().endOf('week').minus({ days: 2 }))}
							level={lowerbound.equals(DateTime.now().startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
							Deze week
						</Button>
					</ButtonGroup>

					<div style={{ display: 'flex', alignItems: 'center', alignContent: 'space-between', height: '35px' }}>
						<span>Rolfilter:</span>
						&nbsp;&nbsp;
						<div style={{ width: '92px' }}><RoleSelectField disabled={absenceType != null} onChange={setRoleFilter} value={roleFilter}/></div>

						<div className="bar-divider">
							<span className="divider-line"></span>
						</div>

						<span>Ab. type filter:</span>
						&nbsp;&nbsp;

						<div style={{ width: '92px' }}>
							<SelectField
								placeholder=''
								isDisabled={roleFilter != null}
								options={[
									{ value: 'UNEMPLOYMENT', label: 'DOP' },
									{ value: 'VACATION', label: 'Vakantie' },
									{ value: 'SICK_LEAVE', label: 'Ziekte' },
									{ value: 'SENIORITY', label: 'Anciënniteit' },
									{ value: 'TIME_FOR_TIME', label: 'HVD' },
									{ value: 'SAVINGS_RECORD', label: 'Briefje' },
									{ value: 'OFFICIAL_REASON', label: 'Attest' },
									{ value: 'PARENTAL_LEAVE', label: 'Ouderschap' },
									{ value: 'TIME_CREDIT', label: 'Tijdkrediet' },
									{ value: 'SPECIAL_LEAVE', label: 'Klein verlet' },
									{ value: 'PARTTIME_3_5', label: '3/5' },
									{ value: 'PARTTIME_4_5', label: '4/5' },
									{ value: 'OTHER', label: 'Overig' },
									// { value: 'UNKNOWN', label: 'Onbekend' },
								]}
								onChange={newValue => setAbsenceType(newValue?.value)}
								value={absenceType}/>
						</div>
					</div>
				</div>

				<div style={{ borderBottom: '1px solid var(--col-grey-200)', padding: 'var(--u-8) 0', marginBottom: 'var(--u-8)' }} />

				<div style={{ position: 'relative', zIndex: 0 }}>
					<LoadingOverlayProvider>
						{workRecordsQuery.loading && <LoadingOverlayTrigger />}
						{wrpdw.map(({ dockWorker, records }) => <WorkRecordDockWorker key={dockWorker.id} dockWorker={dockWorker} workRecords={records} />)}
						{wrpdw.length == 0 && <div style={{ textAlign: 'center', color: 'var(--col-grey-500)', padding: '32px 0', fontStyle: 'italic' }}>Alles binnen selectie is ingevoerd</div>}
					</LoadingOverlayProvider>
				</div>
			</div>
		</div>
	</>;
}