import { Button, ButtonArea, Modal } from '@atrocit/scl';
import { useCallback } from 'react';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { AbsenceType } from './AbsenceType';
import * as xlsx from 'xlsx-js-style';

function forEachDayOfMonth(start, fn) {
	let date = new DateTime(start);
	while (date < start.plus({ months: 1 })) {
		fn(date);
		date = date.plus({ days: 1 });
	}
}

export default function AbsenceMonthExportToExcel({ absences, users, showAllUsers, start, onClose }) {
	const exportToExcel = useCallback(async () => {
		const absThisMonth = absences.filter(abs => abs.end >= start && abs.start < start.plus({ months: 1 }));
		const usersById = showAllUsers ? users.reduce((users, user) => ({ ...users, [user.id]: user }), {}) : absThisMonth.reduce((users, abs) => ({ ...users, [abs.user?.id]: abs.user }), {});
		const groupedAbsences = groupBy(absThisMonth, a => a.user?.id);
		const absByUsers = Object.keys(usersById)
			.map(userId => ({
				absences: groupedAbsences[userId] ?? [],
				user: usersById[userId],
			}))
			.sort((a, b) => a.user.fullName?.trim()?.localeCompare(b.user.fullName?.trim()));

		const xlsx = await import('xlsx-js-style');
		const workbook = xlsx.utils.book_new();

		const output = [];

		const header1 = [ start.toFormat('MMMM yyyy') ];
		forEachDayOfMonth(start, date => header1.push(date.toFormat('dd')));
		output.push(header1);

		const header2 = [ '' ];
		forEachDayOfMonth(start, date => header2.push(date.toFormat('EEE', { locale: 'nl' })));
		output.push(header2);

		for (const { user, absences } of absByUsers) {
			const row = [ user.fullName ];
			forEachDayOfMonth(start, date => {
				const applicableAbsences = absences.filter(a => DateTime.fromISO(a.start) <= date && DateTime.fromISO(a.end) > date);
				row.push(applicableAbsences.map(a => AbsenceType({ type: a.absenceType })).join(', '));
			});
			output.push(row);
		}

		const sheet = xlsx.utils.aoa_to_sheet(output);
		xlsx.utils.book_append_sheet(workbook, sheet, 'Absenties ' + start.toFormat('yyyy-MM'));
		xlsx.writeFile(workbook, "absenties-" + start.toFormat('yyyy-MM') + ".xlsx", { compression: true });
	}, [ absences, users, showAllUsers ]);

	return <Modal title="Exporteren naar excel" onRequestClose={onClose} isOpen={true}>
		<ButtonArea>
			<Button level="primary" onClick={exportToExcel}><span className="fa fa-file-excel-o" />&nbsp; Exporteren</Button>
		</ButtonArea>
	</Modal>;
}