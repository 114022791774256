import { Link, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { DragDropContext } from 'react-beautiful-dnd';
import React, { Suspense, useState } from 'react';
import TopBar from '../../TopBar';
import { FormattedDate } from 'react-intl';
import { Button, LoadingOverlayProvider, LoadingOverlayTrigger } from '@atrocit/scl';
import AbsenceSummary from '../AbsenceSummary';
import LinkButton from '../../lib/forms/LinkButton';
import { DateTime } from 'luxon';
import EditSingleShift from './EditSingleShift';
import EmptyState from '../../lib/EmptyState';
import containerShip from '../../../assets/container_ship.png';
import OffScreenPanel from '../../lib/OffScreenPanel';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
const ExportDayToExcel = React.lazy(() => import('./export/ExportDayToExcel')); // code split heavy XLSX bundle out
const ExportPlanningToExcel = React.lazy(() => import('./export/ExportPlanningToExcel')); // code split the heavy XLSX bundle out

export default function EditDay() {
	const { date, viewId } = useParams();

	const [ exportPlanningToExcel, setExportPlanningToExcel ] = useState(null);
	const [ exportDayToExcel, setExportDayToExcel ] = useState(false);
	const [ absencesPanelOpen, setAbsencesPanelOpen ] = useState(false);

	const startDate = DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day');
	const endDate = DateTime.fromFormat(date, 'yyyy-MM-dd').endOf('day');

	const planningSubscription = useSubscription(gql`subscription Subscription($viewId: Int!, $startDate: Instant!, $endDate: Instant!) {
		viewSubscription(viewId: $viewId, startDate: $startDate, endDate: $endDate) {
			view {
				id,
				name,
				attachedTracks {
					id,
					displayName,
					track {
						id,
						name,
                    },
                },
				shiftDefinitions {
					id,
					name,
					code,
					minimumOverlap,
					startTime,
					endTime,
                }
			},
			plannings {
				id,
				status,
				shiftStart,
				shiftEnd,
				shiftDefinition {
					id,
					name,
					code,
                },
				spots {
                    id
                    role {
                        id, shortCode, name, colorCode
                    },
                    track {
                        id, name
                    },
                    listIndex
                    dockWorker {
                        id,
                        workbookNr,
                        category,
                        user {
                            id, firstName, fullName
                        }
                    },
					start,
					end,
                    remark,
					remarkVisibility,
					externalReference,
					workRecordExempt,
                    workRecord {
                        id,
                        warningOnWageCalculationTarget,
                        calculatedWageTarget,
                        calculatedPremiumTarget,
                    },
                },
				remarks {
					id,
					remarkType,
					remark,
					createdAt,
					track {
						id
					}
                }
            }
		    planSpots {
                id
			    planning {
				    id,
				    shiftStart,
				    shiftEnd,
				    view { id },
				    shiftDefinition {
				    	id, code
                	}
			    },
			    start,
			    end,
			    externalReference,
			    shiftCode,
                role {
                    id, shortCode, name, colorCode
                },
                track {
                    id, name
                },
                listIndex
                dockWorker {
                    id,
                    workbookNr,
                    category,
                    user {
                        id, firstName, fullName
                    }
                },
                remark,
			    remarkVisibility,
                workRecordExempt,
			    workRecord {
				    id,
                    warningOnWageCalculationTarget,
                },
            },
		}
	}`, { variables: { viewId, startDate: startDate.toUTC().toISO(), endDate: endDate.toUTC().toISO() } });
	const view = planningSubscription?.data?.viewSubscription?.view;
	const plannings = planningSubscription?.data?.viewSubscription?.plannings ?? [];
	const planSpots = planningSubscription?.data?.viewSubscription?.planSpots ?? [];

	const absencesQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
        absencesInRange(start: $start, end: $end) {
            id, start, end, user { id, firstName, fullName }, absenceType, absenceStatus, remark
        }
    }`, { variables: { start: startDate.toUTC().toISO(), end: endDate.toUTC().toISO() } });
	const absencesUnfiltered = absencesQuery?.data?.absencesInRange ?? [];
	const absences = absencesUnfiltered.filter(a => a.absenceStatus == 'APPROVED');

	const [ changeSpotLocation ] = useMutation(gql`mutation Mutation($spotId: Int!, $planning: Int!, $track: Int!, $index: Int!) {
        changeSpotLocation(spotId: $spotId, planning: $planning, trackId: $track, listIndex: $index) { id }
    }`);

	return <>
		<Suspense fallback={<></>}>
			{exportPlanningToExcel != null && <ExportPlanningToExcel planning={exportPlanningToExcel} planSpots={planSpots} onClose={() => setExportPlanningToExcel(null)} />}
			{exportDayToExcel && <ExportDayToExcel view={view} plannings={plannings} planSpots={planSpots} onClose={() => setExportDayToExcel(false)} />}
		</Suspense>

		<TopBar title={<span style={{ textTransform: 'capitalize' }}><FormattedDate value={startDate.toJSDate()} weekday="long" day="2-digit" month="2-digit" /></span>}>
			<div style={{ display: 'flex', gap: 'var(--u-8)', alignItems: 'stretch', alignSelf: 'stretch', height: '36px' }}>
				<div style={{ paddingRight: 'var(--u-16)' }}>
					<Button disabled={(plannings ?? []).length == 0} onClick={() => setExportDayToExcel(true)}><span className="fa fa-download" />&nbsp; Download</Button>
				</div>
				<LinkButton to={"/shifts/edit/" + viewId + '/' + startDate.minus({ days: 1 }).toFormat('yyyy-MM-dd')}><span className="fa fa-chevron-left" />&nbsp;</LinkButton>
				<LinkButton to={"/shifts/edit/" + viewId + '/' + startDate.plus({ days: 1 }).toFormat('yyyy-MM-dd')}>&nbsp;<span className="fa fa-chevron-right" /></LinkButton>
			</div>
		</TopBar>

		<div className="grey-page-bg" style={{ position: 'relative' }}>
			<LoadingOverlayProvider>
				{planningSubscription.loading && <LoadingOverlayTrigger />}
				<div className="page-fw">
					<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-24)', paddingTop: 'var(--u-16)', paddingBottom: 'var(--u-384)', overflowX: 'auto' }}>
						{(plannings == null || plannings.length == 0) && <EmptyState img={containerShip} title="Nog geen shifts gepland..." description={<>Misschien wilde je een shift aanmaken <Link to="/shifts">in het overzicht?</Link></>} />}

						<DragDropContext onDragEnd={({ source, destination, draggableId }) => {
							if (destination == null) return;
							if (destination.droppableId == source.droppableId && destination.index == source.index) return;

							const sourcePlanning = parseInt(source.droppableId.split('-')[1], 10);
							const sourceTrack = parseInt(source.droppableId.split('-')[3], 10);
							const targetPlanning = parseInt(destination.droppableId.split('-')[1], 10);
							const targetTrack = parseInt(destination.droppableId.split('-')[3], 10);
							const targetIndex = destination.index;

							// setReorderInProgress({ spotId: draggableId, sourcePlanning, targetPlanning, sourceLocation, targetLocation, sourceIndex: source.index, targetIndex });
							changeSpotLocation({ variables: { spotId: draggableId, planning: targetPlanning, track: targetTrack, index: targetIndex } });
						}}>

							{plannings.map(planning => {
								const usersAjacent = new Set(plannings.filter(p => p.id != planning.id).flatMap(p => p.spots).map(s => s.dockWorker?.user?.id).filter(uid => uid != null));
								return <EditSingleShift
									view={view}
									spots={planSpots.filter(ps => (ps.planning?.shiftDefinition?.code || ps.shiftCode) == planning?.shiftDefinition?.code)}
									usersInAdjacentShift={usersAjacent}
									key={planning.id}
									planning={planning}
									absences={absences}
									onExportToExcel={() => setExportPlanningToExcel(planning)}/>;
							})}
						</DragDropContext>
					</div>
				</div>
			</LoadingOverlayProvider>
		</div>

		<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]}>
			<OffScreenPanel
				expanded={absencesPanelOpen}
				button={<div style={{ paddingRight: '8px' }}><Button onClick={() => setAbsencesPanelOpen(a => !a)}>{absencesPanelOpen ? <span className="fa fa-chevron-right" /> : <span className="fa fa-chevron-left" />}</Button></div>}>
				<div style={{ width: '800px', maxWidth: '90vw', padding: '16px 0' }}>
					<div style={{ padding: '0 16px' }}>
						<h2 style={{ margin: 0, paddingBottom: 'var(--u-16)' }}>Absentie</h2>
					</div>
					<AbsenceSummary absences={absences} date={date} />
				</div>
			</OffScreenPanel>
		</PrivilegeBarrier>
	</>;
}